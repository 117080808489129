@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Public Sans', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #6e6e6e;
}

input:focus {
  outline: none !important;
}

[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  outline: none !important;
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: transparent;
  --tw-ring-color: transparent;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow);
  border: none !important;
  border-width: rgba(0, 0, 0, 0.15) !important;
  border-color: transparent !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield !important;
}

button {
  outline: none !important;
}

.display-linebreak {
  white-space: pre-line;
}
.MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.MuiDataGrid-root {
  border: transparent !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle,
.MuiDataGrid-root .MuiDataGrid-sortIcon,
.MuiCheckbox-colorPrimary.Mui-checked {
  color: #dc3838 !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
  font-weight: 900 !important;
}

.MuiList-root .MuiMenuItem-gutters {
  color: #dc3838 !important;
  padding: 15px 20px !important;
}

.MuiList-root .MuiMenuItem-gutters:hover {
  background-color: #dc3838 !important;
  color: white !important;
}

.register-style [class*='color-default'] {
  --nextui--checkboxColor: #dc3838 !important;
  --nextui--checkboxColorHover: #dc3838 !important;
}

.top-100 {
  top: 100%
}
.bottom-100 {
  bottom: 100%
}
.max-h-select {
  max-height: 150px;
}

.tri-right.left-in:after{
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: -12px;
	right: auto;
  top: 20px;
	bottom: auto;
	border: 6px solid;
	border-color: transparent #dc3838 #dc3838 transparent;
}

.tri-right.right-in:after{
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
	left: auto;
  right: -12px;
  top: 20px;
	bottom: auto;
	border: 6px solid;
	border-color: transparent transparent rgba(220, 56, 56, 0.35)  rgba(220, 56, 56, 0.35);
}

.switch-checked div[aria-checked="true"] {
  background-color: #dc3838 !important;
}

.switch-checked div[aria-checked="false"] {
  background: #E5E5E5 !important;
}
.switch-checked div .nextui-switch-circle {
    background: #DC3838;
}

.switch-checked .nextui-switch-circle {
  background: #DC3838 !important;
}

.avatar > .nextui-user-info{
  display: none;
}

@media (max-width: 767px) {
  .avatar > span {
    width: 3rem !important;
    min-idth: 3rem !important;
    height: 3rem !important;
    max-height: 3rem !important;  
  }
}

@media (min-width: 768px) {
  .avatar > span {
    width: 3.5rem !important;
    min-width: 3.5rem !important;
    height: 3.5rem !important;
    max-height: 3.5rem !important;
  }
}
.file_uploader .uppy-Dashboard-inner {
  width: auto !important;
  padding-top: 3rem;
  padding-bottom: 3rem;
  height: auto !important;
}
h3.nextui-collapse-title{
  font-weight: bold;
}
.nextui-c-evidrZ-fkXlia-isChecked-true .nextui-c-kgjvqv:where(.nextui-c-PJLV)::after {
  border-color: #DC3838 !important;
}
.nextui-c-PJLV-vjOIS-isFocusVisible-true{
  box-shadow: none !important;
}
.nextui-c-itiWTf-cOkRvW-checked-true,
.background_punch{
  background-color: #DC3838 !important;
}
.nextui-c-bOoPrE-iWdsRK-color-default {
  --nextui--loadingColor: #DC3838 !important;
}

.attach-file > span{
  display: none !important;
}

.chat-list {
  height: calc(100vh - 100px);
}

@media (min-width: 767px) {
  .chat-list {
  height: calc(100vh - 240px);
  }
}

.scrollbar-thin::-webkit-scrollbar {
	width: 6px;
	height: 6px;
	border-radius: 6px;
}

.scrollbar-thin::-webkit-scrollbar-thumb {
	border-radius: 8px;
	background-clip: padding-box;
}

.scrollbar-thin {
	scrollbar-width: thin !important;
	border-radius: 8px;
	background-clip: padding-box;
}

/* hide scrollbar but allow scrolling */
.messages {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.messages::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.notification-box,
li {
  padding: 0;
  margin: 0;
}

.notification-box {
  position: fixed;
  bottom: 0;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
  justify-content: flex-end;
}

.notification-box li {
  width: 300px;
  margin: 10px;
  flex: 0 0 100px;
  position: relative;
}

.p-upload .nextui-collapse > div:last-child {
  height: 22rem !important;
}

.door .nextui-collapse > div:last-child {
  height: 38rem !important;
  overflow-y: scroll;
}

.preview .nextui-collapse > div:last-child {
  height: 11rem !important;
  overflow-y: scroll;
}

.preview-upload .nextui-collapse > div:last-child{
  height: 11rem !important;
}

.preview-files .ReactGridGallery_tile:last-child {
  float: none !important;
  background: none !important;
}

.preview-no-upload .nextui-collapse > div:last-child {
  height: 5rem !important;
  overflow-y: scroll;
}

.h-screen-chat {
  height: calc(100vh - 280px);
}

.area-switch .nextui-collapse > div:last-child {
  height: 12rem !important;
}

.area-switch-all .nextui-collapse > div:last-child {
  height: 26rem !important;
}

.area-switch-active .nextui-collapse > div:last-child {
  height: 22rem !important;
}

.area-switch-last .nextui-collapse > div:last-child {
  height: 18rem !important;
}

.area-switch-first .nextui-collapse > div:last-child {
  height: 16rem !important;
}

/* .bg-card{
  background-color: #fcfcfc !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
} */

.tooltip-head{
  background: #dc3838;
}

.tooltip-body{
  background:#e77474 ;
}

.recharts-tooltip-wrapper {
  outline: none;
}

.inspector-badge .nextui-badge-point {
  background-color: #A2A2A2 !important;
}

.nextui-c-iwmZdB-dpkacN-borderWeight-normal {
  border-bottom: 2px solid !important;
}

.nextui-c-iwmZdB {
  border-color: rgba(0, 0, 0, 0.15) !important;
  border-radius: 0 !important;
}

.nextui-c-iwmZdB-dpkacN-borderWeight-normal {
  --nextui--inputBorderWeight: 0px !important;
}

#nextui-modal section {
  overflow-y : auto !important;
  border-radius: 5px;
}

#nextui-modal .nextui-backdrop-content {
  overflow: hidden;
  border-radius: 5px;
}

/* Scrollbar Styling */
#nextui-modal section::-webkit-scrollbar {
    width: 9px;
}

#nextui-modal section::-webkit-scrollbar-track {
  background: #F5F5F5;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    background-clip: padding-box;
}

#nextui-modal section::-webkit-scrollbar-thumb {
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background-color: rgba(155, 155, 155, 0.5);
    background-clip: padding-box;
    border: transparent;
}

#nextui-modal section::-webkit-scrollbar-thumb:hover {
  background-color: rgba(155, 155, 155, 0.8);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: relative;
  max-width: 500px;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-content {
  margin-top: 10px;
}
.modal-buttons{
  margin-top: 1rem;
}

.modal-content p{
  text-align: justify;
}
.loading-container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.loader-container{
  display: flex;
  align-items: center;
}

.apexcharts-canvas{
  margin: 0px auto !important;
}
.max-w-md-entrprise-choice {
  max-width: 37rem !important;
}
.img-signup{
  width: 150px !important;
  height: 150px !important;
}
.notification{
  color: #000;
}
.notif-container{
  background: #F1F2EE;
  height: 45px !important;
}
.comment-text-color{
  color: #f00;
}